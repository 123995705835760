<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/project/banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <div style="margin-bottom:3%;display:flex;justify-content: space-between;">
                <div class="classstyle" style="border-left: 1px solid rgba(0, 0, 0, 0.15);" :class="projectParams.class_id == 0 ? 'classactive' : ''" @click="huanclass(0)">{{$t('project.all')}}</div>
                <div class="classstyle" v-for="(item,index) in projectclass" :key="index" :class="projectParams.class_id == item.id ? 'classactive' : ''" @click="huanclass(item.id)">{{item.class_name}}</div>
            </div>
            <el-row :gutter="30">
                <el-col :span="8" :xs="24" v-for="(item,index) in listfirst" :key="index">
                    <div class="yytlist" @click="nav('projectinfo/'+item.project_id)">
                        <div class="yyimage">
                            <el-image fit="contain" style="width:100%;" :src="item.project_img"></el-image>
                        </div>
                        <div class="yytitle">{{item.project_title}}</div>
                        <div class="yydate">{{item.add_time}}</div>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="24" >
                    <div class="yylist" v-for="(item,index) in list" :key="index" @click="nav('projectinfo/'+item.project_id)">
                        <el-row :gutter="30">
                            <el-col :span="3" :xs="12">
                                <div class="yyindex">{{index+4}}</div>
                                <div class="yydates">{{item.add_time}}</div>
                            </el-col>
                            <el-col :span="21">
                                <div class="yytitl">{{item.project_title}}</div>
                                <div class="yytitles">{{item.project_desc_text}}</div>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
            <el-pagination
                v-if="total>15"
                style="margin-top:3%;text-align:center"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="projectParams.page"
                :page-sizes="[15, 20, 30, 40]"
                :page-size="15"
                layout="total, prev, pager, next"
                :pager-count="5"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { projectList,projectClass} from "@/api/api"; 
export default {
    data(){
        return{
            projectParams:{
                page:1,
                limit:15,
                class_id:0
            },
            listfirst:[],
            list:[],
            total:0,
            projectclass:[],
            title:''
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.indexkeywords')
            },
            {
                name: 'description',
                content: this.$t('seo.indexdescription')
            }
        ]
        }
    },
    async created(){
        this.title = this.$t('layout.application')+'-'+this.$t('seo.indextitle')
        let class_id = this.$route.query.class_id
        if(class_id != undefined){
            this.projectParams.class_id = class_id+''
        }
        let page = this.$route.query.page
        if(page != undefined){
            this.projectParams.page = page-0
        }
        await this.getclassList()
        await this.getList()
    },
    methods:{
        async getList(){
            const{data:res} = await projectList(this.projectParams)
            this.total = res.total
            res.list.forEach((item)=>{
                item.project_img = process.env.VUE_APP_BASE_API+item.project_img
                item.add_time = item.add_time.substring(0,10)
            })
            if(res.list.length<3){
                this.listfirst = res.list
                this.list = []
            }else{
                this.listfirst = [res.list[0],res.list[1],res.list[2]]
                this.list = res.list.splice(3)
            }
            history.pushState(null, null, "/applicationcase?class_id="+this.projectParams.class_id+'&page='+this.projectParams.page)
        },
        async getclassList(){
            const {data:res} = await projectClass()
            this.projectclass = res
        },
        nav(item){
            this.$router.push('/'+item,'_blank')
        },
        handleSizeChange(value){
            this.projectParams.limit = value
            this.getList()
        },
        handleCurrentChange(value){
            this.projectParams.page = value
            this.getList()
        },
        huanclass(class_id){
            this.projectParams.class_id = class_id
            this.getList()
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 70%;
        margin: 0 auto;
        margin-top: 2%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
    @media screen and (max-width: 1540px) {
        .classstyle{
            font-size: 13px !important;
        }
    }
    .classstyle{
        width: 20%;
        height: 54px;
        line-height: 54px;
        text-align: center;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    .classactive{
        background: #0052D9;
        color: #FFFFFF;
    }
    .yytlist{
        background: #FFFFFF;
        cursor: pointer;
    }
    .yyimage{
        width: 100%;
        margin: 0 auto;
    }
    .yytitle{
        padding-left: 5%;
        margin-right: 5%;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-top: 2%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .yydate{
        padding-left: 5%;
        padding-bottom: 3%;
        margin-top: 2%;
        font-size: 14px;
        color: rgba(51, 51, 51, 0.5);
    }
    .yylist{
        margin-top: 2%;
        cursor: pointer;
        .yyindex{
            background: #0052D9;
            font-size: 22px;
            color: #FFFFFF;
            text-align: center;
            line-height: 47px;
            height: 44px;
            font-family: OPPOSans-H;
        }
        .yydates{
            font-size: 13px;
            color: #333333;
            text-align: center;
            height: 30px;
            line-height: 30px;
            background: #FFFFFF;
            font-family: OPPOSans-M;
        }
        .yytitl{
            margin-top: 0.5%;
            font-size: 20px;
            font-weight: 550;
            color: #333333;
        }
        .yytitles{
            margin-top: 10px;
            font-size: 16px;
            color: rgba(51, 51, 51, 0.5);
            text-overflow :ellipsis; /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
            white-space :nowrap; /*让文字不换行*/
            overflow : hidden; /*超出要隐藏*/
        }
    }
    
</style>
<style scoped>
    /deep/ .el-pager li.active{
        color:#0052D9;
    }
    /deep/ .el-pager li.active{
        color:#0052D9;
    }
    /deep/ .el-pager li:hover{
        color:#0052D9;
    }
</style>